import React, { useRef } from "react";
import { graphql } from "gatsby";
import loadable from '@loadable/component';
import Header from '../components/Header';
// import useIntersect from '../hooks/useIntersect';
import { useHasBeenVisible } from '../hooks/useVisibility';
import PageLayout from "../layouts/PageLayout";
import SEO from "../components/seo";
import '../scss/pages/about.scss';

const BelowTheFold = loadable(()=> import('../components/BelowTheFold/About'));

const AboutPage = ({ data, location: { pathname } }) => {
  const { seo, content } = data.page.childMarkdownRemark.frontmatter;
  const { services, blockImage } = data;
  const { html } = data.page.childMarkdownRemark;
  const mainVisible = useRef();
  const hasScrolled = useHasBeenVisible(mainVisible);
  return (
    <PageLayout>
      <SEO title={seo.title} description={seo.metaDescription} ogImage={seo.ogImage.childImageSharp.fixed}  />
      <Header
        subheading={content.headerSection.subheading}
        heading={content.headerSection.heading}
        background={content.headerSection.background}
        color={content.headerSection.color}
      />
      <main ref={mainVisible}>
        {hasScrolled && (
          <BelowTheFold
            blockImage={blockImage}
            body={html}
            CTA={content.CTA}
            services={services}
          />
        )}
      </main>
    </PageLayout>
  );
};

export const AboutPageQuery = graphql`
  query AboutPageQuery {
    page: file(sourceInstanceName: {eq: "pages"}, name: {eq: "about"}) {
      id
      childMarkdownRemark {
        fields {
          slug
        }
        html
        frontmatter {
          seo {
            title
            metaDescription
            ogImage {
              childImageSharp {
                fixed(width: 1200, height: 630, quality: 70) {
                  src
                }
              }
            }
          }
          content {
            headerSection {
              background {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              color
              subheading
              heading
            }
            CTA {
              title
              description
              photo {
                childImageSharp {
                  desktop: fluid(maxWidth: 300, maxHeight: 300, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  mobile: fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              button {
                text
                url
              }
            }
          }
        }
      }
    }
    blockImage: file(relativePath: {eq: "cnc-mill.jpg"}) {
      childImageSharp {
        fluid (maxWidth: 1440, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    services: allFile(filter: {sourceInstanceName: {eq: "services"}}) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 188, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
